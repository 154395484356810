<template>
    <div class="container my-4">
        <h2 class="text-center rounded title mb-4">Uniforme Escolar - COLVM</h2>
        <hr class="divider">

        <!-- Carrusel -->
        <div id="carouselExampleControlsNoTouching" class="carousel  slide" data-bs-touch="false">
            <div class="carousel-inner">
                <!-- Slide 1 -->
                <div class="carousel-item active">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="zoom-container">
                                <img src="../assets/UniformeCOLVM/uniforme_niño.png" class="d-block w-100 image"
                                    alt="Uniforme Diario Niño" @mousedown="startDrag" @mousemove="onDrag"
                                    @mouseup="stopDrag" @mouseleave="stopDrag" @touchstart="startDrag"
                                    @touchmove="onDrag" @touchend="stopDrag" @click="handleClick('uniforme_niño.png')"
                                    :style="{ transform: `translate(${translateX}px, ${translateY}px) scale(${zoomLevel})` }" />
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="descripcion my-2 p-2">
                                <h4 class="">Uniforme Diario - Niño</h4>
                                <hr class="divider">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        <strong>Camisa guayabera</strong> blanca con dos pliegues en su parte frontal,
                                        con logo de
                                        World Vision en la parte frontal superior izquierda y logo del colegio en la
                                        parte superior derecha.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Pantalón clásico color azul turquí. <strong>Para el caso de Preescolar los
                                            niños usan pantalón corto y medias blancas</strong>
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Cinturón negro.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Medias largas color azul turquí.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Zapatos negros tipo colegiales.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Tipo o nombre de la tela (camisa): <strong>Oxford</strong>
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Tipo o nombre de la tela (pantalón): <strong>Lino</strong>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- Slide 2 -->
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="zoom-container">
                                <img src="../assets/UniformeCOLVM/uniforme_niña.png" class="d-block w-100 image"
                                    alt="Uniforme Diario Niña" @mousedown="startDrag" @mousemove="onDrag"
                                    @mouseup="stopDrag" @mouseleave="stopDrag" @touchstart="startDrag"
                                    @touchmove="onDrag" @touchend="stopDrag" @click="handleClick('uniforme_niña.png')"
                                    :style="{ transform: `translate(${translateX}px, ${translateY}px) scale(${zoomLevel})` }" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="descripcion my-2 p-2">
                                <h4 class="">Uniforme Diario - Niña</h4>
                                <hr class="divider">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        <strong>Blusa estilo safari</strong> con cinturón color blanco, con logo de
                                        World Vision en la
                                        parte frontal superior izquierda y logo del colegio en la parte superior derecha.

                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Falda color azul turquí con canesú y tres pliegues profundos delante y
                                        detrás, <strong>esta prenda debe ser usada a altura de la rodilla.</strong>
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Medias largas color blanco.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Zapatos negros tipo colegiales.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Tipo o nombre de la tela (blusa): <strong>Oxford</strong>
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Tipo o nombre de la tela (Falda): <strong>Lino</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Slide 3 -->
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="zoom-container">
                                <img src="../assets/UniformeCOLVM/uniforme_deportivo.png" class="d-block w-100 image"
                                    alt="Uniforme Deportivo" @mousedown="startDrag" @mousemove="onDrag"
                                    @mouseup="stopDrag" @mouseleave="stopDrag" @touchstart="startDrag"
                                    @touchmove="onDrag" @touchend="stopDrag"
                                    @click="handleClick('uniforme_deportivo.png')"
                                    :style="{ transform: `translate(${translateX}px, ${translateY}px) scale(${zoomLevel})` }" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="descripcion my-2 p-2">
                                <h4 class="">Uniforme Diario - Deportivo</h4>
                                <hr class="divider">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Camiseta blanca, con franjas azules a los lados y líneas anaranjadas, con
                                        cuello blanco, franjas delgadas azul y anaranjada.

                                    </li>
                               
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Controles del carrusel fuera del contenedor -->
        </div>

        <hr class="divider">

        <div class="text-center my-2">
            <a 
            href="https://drive.google.com/drive/folders/1G0S_GbC5ARka6HgupEZuTfv_o6A10xnV?usp=sharing" 
            target="_blank" 
            rel="noopener noreferrer"
            class="btn btn-download">
            <i class="fas fa-download"></i> Clic aquí para descargar el LOGO
            </a>
        </div>
        


        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>

        <!-- Modal -->
        <div class="modal shadow rounded fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">

                <div class="modal-content">
                    <div class="modal-header  ">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <img :src="modalImageSrc" class="img-fluid" alt="Imagen Ampliada">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            zoomLevel: 1,
            isDragging: false,
            startX: 0,
            startY: 0,
            translateX: 0,
            translateY: 0,
            modalImageSrc: ''
        };
    },
    methods: {
        startDrag(event) {
            const e = event.type === 'touchstart' ? event.touches[0] : event;
            if (this.zoomLevel > 1) {
                this.isDragging = true;
                this.startX = e.clientX - this.translateX;
                this.startY = e.clientY - this.translateY;
            }
        },
        onDrag(event) {
            if (this.isDragging) {
                const e = event.type === 'touchmove' ? event.touches[0] : event;
                this.translateX = e.clientX - this.startX;
                this.translateY = e.clientY - this.startY;
            }
        },
        stopDrag() {
            this.isDragging = false;
        },
        handleClick(image) {
            if (window.innerWidth > 768) { // Detecta si es PC
                this.modalImageSrc = require(`@/assets/UniformeCOLVM/${image}`);
                new Modal(document.getElementById('imageModal')).show();
            } else {
                this.toggleZoom(); // Agrega la funcionalidad de zoom en móviles
            }
        },
        toggleZoom() {
            if (this.zoomLevel === 1) {
                this.zoomLevel = 2;
            } else {
                this.zoomLevel = 1;
                this.translateX = 0;
                this.translateY = 0;
            }
        }
    }
};
</script>

<style scoped>

.btn-download {
    background-color: var(--color-se);
    /* Cambia el color de fondo */
    color: white;
    /* Cambia el color del texto */
    font-size: 1.2rem;
    /* Aumenta el tamaño de la fuente */
    padding: 10px 20px;
    /* Añade relleno al botón */
    border-radius: 30px;
    /* Haz el botón más redondeado */
    transition: background-color 0.3s ease, transform 0.2s ease;
    /* Transición suave para el cambio de color y efecto hover */
    text-decoration: none;
    /* Elimina el subrayado */
}

.btn-download:hover {
    border: 2px solid var(--color-pr);
    background-color: var(--color-co);
    color: var(--color-pr);
    transform: translateY(-2px);
    /* Efecto de desplazamiento hacia arriba */
    font-weight: bold;
    /* Aumenta la sombra en hover */
}

.title {
    font-weight: bold;
    padding: 5px;
    text-transform: uppercase;
    color: var(--color-se);
}

.list-group-item {
    border: none;
    /* Elimina el borde predeterminado */
    position: relative;
    /* Posiciona el ícono de viñeta correctamente */
    padding-left: 1.5rem;
    /* Añade espacio para el ícono de viñeta */
    list-style-type: disc;
    /* Cambia el tipo de lista a viñetas */
    color: var(--color-se);
    background-color: #f8f9fa;
    font-size: 1.1rem;
    /* Aumenta el tamaño del texto */

}

.list-group-item::before {
    content: '•';
    /* Símbolo de viñeta */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.35rem;
    /* Tamaño del ícono de viñeta */
    color: var(--color-pr);
    /* Color del ícono de viñeta */
}

h4 {
    color: var(--color-pr);
    text-transform: uppercase;
    font-weight: bold;
}


.zoom-container {
    overflow: hidden;
    position: relative;
}

img {
    transition: transform 0.3s ease;
    cursor: pointer;
}

.carousel-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.descripcion {
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel-control-prev,
.carousel-control-next {
    filter: invert(1);
}

.image {
    cursor: pointer;
    touch-action: pan-x pan-y;
}

@media (max-width: 768px) {
    .image {
        transition: transform 0.3s ease;
    }
}

.modal-dialog {
    max-width: 80%;
}

.modal-body img {
    width: 100%;
    height: auto;
}
</style>
