<template>
    <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">

            <router-link class="navbar-brand" to="/">
                <img src="@/assets/LogoColVM/Colvm_color2.svg" width="250" alt="colvm" />
            </router-link>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto fw-bold mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link" :class="{ active: $route.name === 'home' }" to="/" role="button"
                            aria-current="page">
                            <i class="bi bi-house-door-fill"></i>INICIO
                        </router-link>
                    </li>

                    <li class="nav-item dropdown " :class="{ show: isDropdownActive }">
                        <a class="nav-link dropdown-toggle " href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false" @click="toggleDropdown">
                            <i class="bi bi-person"></i> CONÓZCANOS
                        </a>
                        <ul class="dropdown-menu dropdown-menu-light px-2" :class="{ show: isDropdownActive }">
                            <li><a class="dropdown-item" href="#quienes-somos">HISTORIA</a></li>
                            <li><a class="dropdown-item" href="#mision-vision">Misión y Visión</a></li>
                            <li><a class="dropdown-item" href="#">Objetivos Institucionales</a></li>
                            <li class="nav-item">
                                <router-link class="dropdown-item" aria-current="page"
                                    :to="{ name: 'UniformeColVMView' }"
                                    :class="{ active: $route.name === 'UniformeColVMView' }">

                                    Uniforme Escolar
                                </router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                                <router-link class="dropdown-item" :class="{ active: $route.name === 'PPDView' }"
                                    :to="{ name: 'PPDView' }">
                                    Política y Privacidad
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link disabled" aria-current="page" href="#"><i
                                class="bi bi-journal-text"></i>PLATAFORMAS DIGITALES</a>
                    </li>
                    <li class="nav-item dropdown" :class="{ show: isDropdownActive }">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">ÁREA ACADEMICA</a>
                        <ul class="dropdown-menu dropdown-menu-light px-2" :class="{ show: isDropdownActive }">
                            <li class="nav-item">
                                <router-link class="dropdown-item" aria-current="page"
                                    :to="{ name: 'EvaluacionDView' }"
                                    :class="{ active: $route.name === 'EvaluacionDView' }">

                                    Evaluación Docente
                                </router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <!-- <li>
                                <router-link class="dropdown-item" :class="{ active: $route.name === 'PPDView' }"
                                    :to="{ name: '' }">
                                    
                                </router-link>
                            </li> -->
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" @click.prevent="openForm" href="#"><i
                                class="bi bi-envelope-arrow-down"></i>PQRS</a>
                        <!-- <a class="nav-link" aria-current="page" data-bs-toggle="modal" data-bs-target="#PQRS" href="#">PQRS</a> -->
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" :to="{ name: 'ComunicacionesView' }"
                            :class="{ active: $route.name === 'ComunicacionesView' }">
                            <i class="bi bi-messenger"></i>COMUNICACIONES</router-link>
                    </li>


                </ul>
                <form class="d-flex">
                    <div class="input-group search-input-group">
                        <input class="form-control search-input" type="search" placeholder="Buscar" aria-label="Buscar">
                        <button class="btn btn-outline-success" type="submit">
                            <i class="bi bi-search"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </nav>
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';




export default {
    data() {
        return {
            isNavbarOpen: false,
        };
    },
    setup() {
        const isDropdownActive = ref(false);
        const route = useRoute();

        const toggleDropdown = () => {
            isDropdownActive.value = !isDropdownActive.value;
        };

        watch(
            () => route.name,
            (newRouteName) => {
                if (newRouteName === 'PPDView') {
                    isDropdownActive.value = true;
                } else {
                    isDropdownActive.value = false;
                }
            },
            { immediate: true }
        );

        return {
            isDropdownActive,
            toggleDropdown,
        };
    },
    methods: {

        toggleNavbar() {
            this.isNavbarOpen = !this.isNavbarOpen;
        },
        closeNavbar() {
            this.isNavbarOpen = false;
        },

        openForm() {
            window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSd5UUfVY_nZxrTUU5Ydi2m-hKG0ouvdf-JXjk8K2YGH6Lg5fQ/viewform",
                "_blank",
                "width=800,height=1000"
            );
        },
    },
};
</script>

<style scoped>
.search-input-group {
    width: 130px;
    /* Tamaño inicial */
    transition: width 0.4s ease;
}

.search-input-group:hover {
    width: 200px;
    /* Tamaño al hacer hover */
}

.search-input {
    transition: width 0.4s ease;
}


.navbar-custom {
    background-color: #fff;
    padding: 0px 0;
    /* Ajusta el espacio interno para centrar verticalmente */
}

.navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-bar-collapse {
    display: flex;
    justify-content: center;
}

.navbar-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;
    /* Espacio entre los elementos de navegación */
}

.nav-item {
    border-radius: 5px;
    padding: 5px 0px;
}

.nav-item a {
    color: #304b76;
    text-decoration: none;
    font-size: 16px;
    border: 2px solid transparent;
    transition: border 0.3s ease-in-out;
    border-radius: 8px;
}

.nav-item a:hover {
    border: 2px solid var(--color-pr);
    ;
    font-size: 16.1px;
    color: var(--color-pr);
    ;

}

.nav-item a.active {
    color: var(--color-pr);
    ;
}

/* .navbar-nav .nav-item .nav-link {
  margin-right: 10px;
} */

/* Estilo personalizado para el dropdown */
.navbar .dropdown-menu .dropdown-item.active,
.navbar .dropdown-menu .dropdown-item.active:hover,
.navbar .dropdown-menu .dropdown-item.active:focus {
    background-color: #ffffff;
    /* Fondo blanco para el enlace activo */
    color: var(--color-pr);
    ;
    /* Texto negro para el enlace activo, opcional */
    border: 2px solid var(--color-pr);
    ;
}

/* Estilo para el hover y focus */
.navbar .dropdown-menu .dropdown-item:hover,
.navbar .dropdown-menu .dropdown-item:focus {
    background-color: #fff3dc;
    /* Fondo claro cuando el enlace está en hover */
}
</style>
