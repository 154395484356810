<template>
  <div class="container my-5">
    <div class="card shadow-lg border-light">
      <div class="card-body">
        <h1 class="card-title text-center mb-4">Política de Privacidad - Colegio Visión Mundial ASODESI</h1>
        
        <p class="card-text mb-4">
          En cumplimiento de la Ley 1581 de 2012 sobre Protección de Datos Personales y su Decreto Reglamentario 1377 de 2013, el Colegio Visión Mundial ASODESI informa lo siguiente:
        </p>

        <div class="accordion" id="privacyPolicyAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Autorización para el Tratamiento de Datos Personales
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#privacyPolicyAccordion">
              <div class="accordion-body">
                En calidad de padre(s) de familia y/o acudiente(s) del estudiante interesado en ingresar al Colegio Visión Mundial ASODESI, autorizo de manera expresa a la institución para llevar a cabo el tratamiento de los datos personales. Este tratamiento incluye la recolección, almacenamiento, uso y supresión de datos personales indispensables, opcionales y sensibles del estudiante interesado, así como de los datos del padre(s) y/o acudiente(s) que sean necesarios para gestionar el proceso de admisión y la eventual prestación del servicio educativo.
              </div>
            </div>
          </div>

          <div class="accordion-item mt-3">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Transferencia de Datos Personales
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#privacyPolicyAccordion">
              <div class="accordion-body">
                Asimismo, autorizo la transferencia de estos datos a entidades públicas o administrativas en ejercicio de sus competencias legales o cuando sea requerido por orden judicial. También autorizo la transferencia de datos a terceros con los cuales el Colegio Visión Mundial ASODESI haya celebrado contratos de prestación de servicios, siempre que dichas tareas estén relacionadas con el proceso de admisión o la prestación del servicio educativo.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style scoped>


.card {
  border-radius: 1rem;
}

.card-title {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--color-se);
  text-wrap: 100;
}

.card-text {
  text-align: justify;
  line-height: 1.7;
  text-align: justify;

}

.accordion-button {
  color: var(--color-se);
  border: 1px solid var(--color-se);
  text-align: justify;

}

.accordion-button:not(.collapsed) {
  color: var(--color-co); 
  background-color: var(--color-pr);
}

.accordion-button:hover {
  color: var(--color-pr);
   border: 1px solid var(--color-pr);

}
.accordion-button:focus {
  color: var(--color-co);
  background-color: var(--color-pr);
   border: 1px solid var(--color-pr);

}

.accordion-button:focus, /* Para el estado de enfoque (cuando se hace clic) */
.accordion-button:active { /* Para el estado activo (cuando se mantiene presionado) */
  box-shadow: 0 0 0px 0px rgba(0, 0, 255, 0.5); /* Resplandor azul */
}

.accordion-body {
  background-color: #f8f9fa;
  text-align: justify;

}
</style>
